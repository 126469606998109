
const $ = window.$ = require('jquery');

import Controller from './modules/controller';
import Modal from './modules/modal';
import Youtube from './modules/youtube'

(function($){

	Controller.standby();
	Modal.init();
	Youtube.init();

	var Header = {

		init: function() {

			this.view = {};
			this.view.header = $('.pc-header');

			this.height = $('.js-fixed').height();
			Controller.view.window.on('load scroll', this.fixed.bind(this));


			this.view.spHeader = $('.sp-header');
			this.view.toggleMenu = this.view.spHeader.find('.menu-toggle');
			this.view.toggleMenu.on('click', this.toggle.bind(this));
		},
		fixed : function() {

			if(Controller.view.window.y > this.height) {
				this.view.header.addClass('is-fixed')
			}

			if(Controller.view.window.y < this.height) {
				this.view.header.removeClass('is-fixed')
			}

		},
		toggle: function(el) {
			if(Controller.device != 'SP') {
				return
			}
			if($(el.currentTarget).hasClass('is-active')) {
				$(el.currentTarget).removeClass('is-active');
				this.view.spHeader.find('.global-nav-wrapper').removeClass('is-active')

			} else {
				$(el.currentTarget).addClass('is-active');
				this.view.spHeader.find('.global-nav-wrapper').addClass('is-active')
			}

		}

	}

	Header.init();

	var Slider = {
		init: function() {
			if(!$('.swiper-container').get(0)) {
				return
			}

			this.slider();
		},
		slider: function() {
			let mySwiper = new Swiper ('.main-slide', {
				loop: true,
				autoplay: {
					delay: 4000,
					disableOnInteraction: false
				},
				speed: 2000,
				effect: 'fade',
				pagination: {
				  el: '.main-slide .swiper-pagination',
				  type: 'bullets',
				  clickable: true,
				},
				on : {
					init: function(){
						$('.main-slide .swiper-slide').eq(this.activeIndex).addClass('is-active')
					},
					fromEdge :function() {
						$('.main-slide .swiper-slide:last-child').removeClass('is-active')
					}
				}
			});
			mySwiper.on('slideChangeTransitionStart', function () {
				$('.main-slide .swiper-slide').eq(this.activeIndex).addClass('is-active');
			});
			mySwiper.on('slideChangeTransitionEnd', function () {
				$('.main-slide .swiper-slide').eq(this.activeIndex - 1).removeClass('is-active');
			});
		}
	}

	Slider.init();

	var Faq = {

		init: function() {

			if(!$('.faq-container').get(0)) {
				return
			}

			$('.fag-group-title').on('click', this.toggle.bind(this))
		},
		toggle: function(el) {

			$(el.currentTarget).next().slideToggle(300);
			$(el.currentTarget).parents('.faq-group').toggleClass('is-active');


		}
	}

	Faq.init();

	var Cast = {

		init:function() {
			let mySwiper = new Swiper ('.cast-slide', {
				loop: true,
				autoplay: {
					delay: 3000,
					disableOnInteraction: false
				},
				speed: 1000,
				effect: 'fade',
				pagination: {
				  el: '.cast-slide .swiper-pagination',
				  type: 'bullets',
				  clickable: true,
				},
				on : {
					init: function(){
						$('.cast-slide .swiper-slide').eq(this.activeIndex).addClass('is-active')
					},
					fromEdge :function() {
						$('.cast-slide .swiper-slide:last-child').removeClass('is-active')
					}
				}
			});

		}

	}

	Cast.init();

	var News = {

		init:function() {
			if(!$('.archive-news .category-list').get(0)) {
				return
			}
			$('.category-list-item').on('click',this.toggle.bind(this));
		},
		toggle: function(el) {
			$('.category-list-item').removeClass('is-active');
			$(el.currentTarget).addClass('is-active');
			var className = $(el.currentTarget).data('cat');

			if(className != 'all') {
				$('.news-list-item').removeClass('is-show');
				var target = document.getElementsByClassName(className);
				$(target).addClass('is-show');
			}else {
				$('.news-list-item').addClass('is-show');
			}



		}

	}

	News.init();

	var Utility = {
		init:function() {
			var ua = navigator.userAgent.toLowerCase();
			var isiOS = (ua.indexOf('iphone') > -1) || (ua.indexOf('ipad') > -1);
			if(isiOS) {
				var viewport = document.querySelector('meta[name="viewport"]');
				if(viewport) {
					var viewportContent = viewport.getAttribute('content');
					viewport.setAttribute('content', viewportContent + ', user-scalable=no');
				}
			}

			$('.smooth').on('click', this.smooth.bind(this));
			$('.checkbox-wrapper label').on('click', this.check.bind(this));
			$(window).on('load', this.pageScroll.bind(this));
		},
		smooth: function(el) {
			var className = $(el.currentTarget).data('anchor');
			var target = document.getElementsByClassName(className);

			var offset;
			if(Controller.device === 'PC') {
				offset = $(target).offset().top - 80;
			} else {

				$('.global-nav-wrapper').removeClass('is-active');
				$('.fixed-menu-item').removeClass('is-active');
				offset = $(target).offset().top;
			}

			$('body, html').animate({scrollTop: offset}, 300, 'swing');
		},
		pageScroll: function() {
			var urlParam = location.search.substring(1);
			if(urlParam != '') {

				var target = document.getElementsByClassName(urlParam);

				var offset;
				if(Controller.device === 'PC') {
					offset = $(target).offset().top - 80;
				} else {
					offset = $(target).offset().top;
				}

				$('body, html').animate({scrollTop: offset}, 300, 'swing');
			}
		},
		check: function(el) {
			el.preventDefault();

			if($(el.currentTarget).hasClass('active')) {
				$(el.currentTarget).find('input').prop('checked',false);
			} else {
				$(el.currentTarget).find('input').prop('checked',true);
			}

			$(el.currentTarget).toggleClass('active');


 		}
	}

	Utility.init();

})(jQuery)
