const Youtube = {

	init: function () {
		this.view = {};

		if (!$('#player').get(0)) {
			return;
		}
		this.player = null;

		var tag = document.createElement('script');

		tag.src = "https://www.youtube.com/iframe_api";
		var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


		Controller.view.window.on('load', this.createPlayer.bind(this));

		$(document).on('click', '.open-youtube', this.openPlayer.bind(this));

		$(document).on('click', '.close-youtube', this.stopPlayer.bind(this));
	},
	createPlayer: function() {

		var id = $('.open-youtube').eq(0).data('youtube');//初期セット動画ID

		this.player = new YT.Player('player', {
			height: '360',
			width: '640',
			videoId: id,
		});
	},
	openPlayer: function (el) {//再生動画取得
		var id = $(el.currentTarget).data('youtube');
		this.player.loadVideoById(id);
	},
	stopPlayer: function () {//再生停止
		this.player.stopVideo();
	},
	playVideo: function (event) {//再生開始
		event.target.playVideo();
	},

}

export default Youtube;
